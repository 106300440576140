export enum LangEnum {
  VI = "vi",
  EN = "en"
}

export enum DateTimeEnum {
  DD_MM_YYYY_HH_MM_SS_Z = "DD/MM/YYYY HH:mm:ssZ",
  YYYY_MM_DD_HH_MM_SS_Z = "YYYY/MM/DD HH:mm:ssZ",
}

export enum StatusCode {
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
}

export enum GroupEnum {
  ADMIN = "ADMIN",
  MERCHANT = "MERCHANT",
  CUSTOMER = "CUSTOMER"
}
